
import { computed, ComputedRef, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical, mdiFilter, mdiLock, mdiPlus } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'
import { tf } from '@/plugins/i18n'

import { useNotify } from '@/store'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { handleError } from '@/utils/handleError'

import { Rights } from '@/api/types/right'
import { useDeleteUser, useGetUsers } from '@/api/user'
import { User } from '@/api/types/user'
import { usersApi } from '@/api'
import { FILTER_FIELD_KEY } from '@/views/admin/users/types'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { useGetRoles } from '@/api/role'
import { UserRoleDisplay } from '@/api/types/role'

export default defineComponent({
  name: 'Users',
  components: {
    CommonTableView,
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    AddUserDialog: () => import('@/views/admin/users/components/AddUserDialog.vue'),
    EditUserDialog: () => import('@/views/admin/users/components/EditUserDialog.vue'),
    UsersFilterBar: () => import('@/views/admin/users/components/UsersFilterBar.vue'),
  },
  setup(_, { root }) {
    const { addNotification } = useNotify()

    const TABLE_HEADERS = [
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
      },
      {
        text: root.$t('form.field.firstName') as string,
        value: 'firstName',
      },
      {
        text: root.$t('form.field.lastName') as string,
        value: 'lastName',
      },
      {
        text: root.$t('form.field.enabled') as string,
        value: 'enabled',
      },
      {
        text: root.$t('form.field.role') as string,
        value: 'roles',
        sortable: false,
      },
      {
        text: root.$t('form.field.professionalUnitsAsLeader') as string,
        value: 'professionalUnitsAsLeader',
        sortable: false,
      },
      {
        text: root.$t('form.field.organizationalUnitsAsLeader') as string,
        value: 'organizationalUnitsAsLeader',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const isAddModalOpen = ref(false)

    const isEditModalOpen = ref(false)

    const userToEdit = ref<null | User>(null)

    const isDeleteModalOpen = ref(false)

    const userToDelete = ref<null | User>(null)

    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    function isOwnUser(id: number) {
      return currentUser.value?.id === id
    }

    function onUserEdit(_, { item: user }: { item: User }): void {
      if (hasSufficientRights(Rights.USER_UPDATE) && !isOwnUser(user.id)) {
        userToEdit.value = { ...user }

        isEditModalOpen.value = true
      }
    }

    const { deleteUser: deleteUserXhr } = useDeleteUser()

    async function deleteUser(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteUserXhr(userToDelete.value!.id)

        addNotification({
          text: root.$t('users.delete.success') as string,
          type: 'success',
        })

        isDeleteModalOpen.value = false

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onUserDelete(user: User): void {
      userToDelete.value = cloneDeep(user)

      isDeleteModalOpen.value = true
    }

    const isFilterDropdownOpen = ref(false)

    const { exec: getRoles, data: roles, isLoading: isLoadingRoles } = useGetRoles()

    const translatedRoleOptions: ComputedRef<UserRoleDisplay[]> = computed(() =>
      roles.value.map((r) => Object.assign(r, { displayName: tf(`roles.names.${r.name}`, r.name).toString() }))
    )

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!roles.value?.length) {
          getRoles({ params: { size: 9999 } })
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('admin.users.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Roles,
        value: null,
        label: root.$t('admin.users.filters.roles'),
        items: computed(() => translatedRoleOptions.value ?? []),
        isLoading: computed(() => isLoadingRoles.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
    ])

    const { exec: getUsers, data: users, isLoading: isLoadingUsers, paginationResponse } = useGetUsers()

    const totalUsers = computed(() => paginationResponse.value.totalElements)

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'id', desc: false })

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getUsers({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    function onCloseAddEditDialog() {
      isAddModalOpen.value = false

      isEditModalOpen.value = false

      userToEdit.value = null

      debouncedCb.value()
    }

    const { invalidateToken } = usersApi.useInvalidateToken()

    const isTokenInvalidationOpen = ref(false)

    const userToInvalidate = ref<User | null>(null)

    function openTokenInvalidationDialog(user: User): void {
      userToInvalidate.value = user
      isTokenInvalidationOpen.value = true
    }

    function onInvalidateToken(): void {
      if (userToInvalidate.value) {
        invalidateToken(userToInvalidate.value.id).then(() => {
          isTokenInvalidationOpen.value = false
          userToInvalidate.value = null

          addNotification({
            text: root.$t('admin.users.invalidateToken.success') as string,
            type: 'success',
          })
        })
      }
    }

    const onUserEdited = (editedUser: User) => {
      const index = users.value.findIndex((user) => user.id === editedUser.id)
      if (index >= 0) {
        root.$set(users.value, index, editedUser)
      }
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiLock,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        TABLE_HEADERS,

        FILTER_FIELDS,

        Rights,
      },
      state: {
        users,
        isLoadingUsers,
        totalUsers,

        isDeleteModalOpen,

        isEditModalOpen,
        isAddModalOpen,
        userToEdit,

        isFilterDropdownOpen,
        vuetifyTableOptions,

        isTokenInvalidationOpen,
      },
      functions: {
        deleteUser,

        isOwnUser,

        hasSufficientRights,
        debouncedCb,

        openTokenInvalidationDialog,

        tf,
      },
      listeners: {
        onUserEdit,
        onUserDelete,

        onCloseAddEditDialog,

        onInvalidateToken,

        onUserEdited,

        onToggleFilterDropdown,
      },
    })
  },
})
