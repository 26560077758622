var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.users,
      "loading": _vm.state.isLoadingUsers,
      "with-info": false,
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalUsers
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onUserEdit,
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onUserDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "my-4 ma-0",
          attrs: {
            "justify": "end",
            "align": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "elevation": "0",
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.listeners.onToggleFilterDropdown
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1), _c('v-spacer'), _vm.functions.hasSufficientRights(_vm.constants.Rights.USER_CREATE) ? _c('v-btn', {
          staticClass: "ml-2 rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('form.action.addUser')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('UsersFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "roles",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _vm._l(item.roles, function (role) {
          return _c('p', {
            key: role.id
          }, [_vm._v(" " + _vm._s(_vm.functions.tf("roles.names.".concat(role.name), role.name)) + " ")]);
        });
      }
    }, {
      key: "item.actions.delete",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.USER_DELETE) || _vm.functions.isOwnUser(item.id) ? _c('div') : _vm._e()];
      }
    }, {
      key: "item.actions.options",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          attrs: {
            "dense": ""
          },
          on: {
            "click": function click($event) {
              return _vm.functions.openTokenInvalidationDialog(item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiLock) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('admin.users.invalidateToken.action')) + " ")])], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  }, [_c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "width": "300"
    },
    model: {
      value: _vm.state.isTokenInvalidationOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTokenInvalidationOpen", $$v);
      },
      expression: "state.isTokenInvalidationOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('admin.users.invalidateToken.action')) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t('admin.users.invalidateToken.text')) + " ")]), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isTokenInvalidationOpen = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.cancel')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onInvalidateToken();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('admin.users.invalidateToken.agree')) + " ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "scrollable": "",
      "width": "1000"
    },
    model: {
      value: _vm.state.isAddModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddModalOpen", $$v);
      },
      expression: "state.isAddModalOpen"
    }
  }, [_vm.state.isAddModalOpen ? _c('AddUserDialog', {
    on: {
      "user-added": function userAdded($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "scrollable": "",
      "width": "1000"
    },
    model: {
      value: _vm.state.isEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isEditModalOpen", $$v);
      },
      expression: "state.isEditModalOpen"
    }
  }, [_vm.state.isEditModalOpen ? _c('EditUserDialog', {
    attrs: {
      "user": _vm.state.userToEdit
    },
    on: {
      "user-edited": _vm.listeners.onUserEdited,
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    }
  }) : _vm._e()], 1), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteModalOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.functions.deleteUser();
      }
    },
    model: {
      value: _vm.state.isDeleteModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteModalOpen", $$v);
      },
      expression: "state.isDeleteModalOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('users.delete.prompt')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }